.date-picker-border .ant-calendar-picker-input{
   border: none !important;
}

.date-picker-border .ant-calendar-picker-input:focus{
   border: none !important;
}

.ant-select-dropdown.ant-select-dropdown--single.ant-select-dropdown-placement-bottomLeft,
.ant-calendar-picker-container{
   position: absolute !important;
   z-index: 9999 !important;
}

.ant-modal-content .ant-modal-body .MuiGrid-root{
   margin: 0;
   width: 115%;
}

