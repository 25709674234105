.date-picker-border .ant-calendar-picker-input{
   border: none !important;
}


.date-picker-border .ant-calendar-picker-input:focus{
   border: none !important;
}

.MuiTypography-root .ant-calendar-picker-icon{
   /*background-color: #ddd;*/
}
