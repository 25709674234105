.sc-header {
  background: linear-gradient(to right,#00C9E4, 50%, #00DFD7);
  min-height: 10px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  color: white;
  padding: 10px;
  box-shadow: 0 1px 4px rgba(0,0,0,.2);
  position: relative;
  box-sizing: border-box;
  display: flex;
}

.sc-header--close-button img {
  width: 100%;
  height: 100%;
  padding: 13px;
  box-sizing: border-box;
}

.sc-header--img {
  border-radius: 50%;
  align-self: center;
  /*padding: 10px;*/
}

.advisory{
  border-radius: 50%;
  align-self: center;
  /*padding: 10px;*/
}
.sc-header--team-name {
  align-self: center;
  /*padding: 10px;*/
  flex: 1;
  user-select: none;
  border-radius: 5px;
  padding-left: 85px;
}

.sc-header--close-button {
  width: 40px;
  align-self: center;
  height: 40px;
  margin-right: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 5px;
}

.sc-header--close-button:hover {
  /*background: #4882ed;*/
}
.sc-header--close-text{
  color: #ffffff;
  font-size: 4vw;
  font-weight: 500;
  margin: 0vw
}

@media (max-width: 450px) {
  .sc-header {
    border-radius: 0px;
  }
}
